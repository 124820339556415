import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const DeliveryCompany = (props) => {
  const { user } = props;
  const [deliveryCompanyData, setDeliveryCompanyData] = useState(null);
  const [deliveryCompanyNew, setDeliveryCompanyNew] = useState({ delivery_company_id: null, delivery_company_name: '', delivery_company_url: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();


  const refreshDeliveryCompany = async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {};
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deliverycompany/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.delivery_company_id - b.delivery_company_id);

    setDeliveryCompanyData(data);
    const new_data = { delivery_company_id: null, delivery_company_name: '', delivery_company_url: '', upd_date: null };
    setDeliveryCompanyNew(new_data);
  }

  const handleDeliveryCompanyRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.delivery_company_name) {
      window.alert('配送会社を入力してください。');
      return;
    }
    const params = {
      delivery_company_id: data.delivery_company_id,
      delivery_company_name: data.delivery_company_name,
      delivery_company_url: data.delivery_company_url,
      upd_date: data.upd_date
    };
    console.log(params);
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deliverycompany/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshDeliveryCompany();
      }
      setLockData(false);
    }
  }

  const handleDeliveryCompanyDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`配送会社[${data.delivery_company_id}:${data.delivery_company_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      delivery_company_id: data.delivery_company_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deliverycompany/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshDeliveryCompany();
      }
      setLockData(false);
    }
  }

  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...deliveryCompanyNew, [key]: event.target.value }
      setDeliveryCompanyNew(new_data);
    } else {
      const _deliveryCompanyData = [...deliveryCompanyData]
      _deliveryCompanyData[index] = { ..._deliveryCompanyData[index], [key]: event.target.value }
      setDeliveryCompanyData(_deliveryCompanyData)
    }
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handleDeliveryCompanyRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.upd_date === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleDeliveryCompanyDeleteClick(data)}>
        削除
      </button>
    )
  }

  useEffect(() => {
    refreshDeliveryCompany();
  }, []);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(deliveryCompanyData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {deliveryCompanyData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {deliveryCompanyData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    配送会社ID
                  </td>
                  <td className="text-center">
                    配送会社名
                  </td>
                  <td className="text-center">
                    配送状況確認URL<br />##TRACKING_NUMBER##：伝票番号
                  </td> 
                  <td className="text-center">
                    作成者<br />更新者
                  </td>
                  <td className="text-center">
                    作成日時<br />更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 配送会社ID */}
                  <td className="text-center align-middle">
                    新規
                  </td>
                  {/* 配送会社名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"delivery_company_name_new"} name="delivery_company_name[]" value={deliveryCompanyNew.delivery_company_name || ''} onChange={handleChangeCell('new', 'delivery_company_name')}></input>
                  </td>
                  {/* 配送状況確認URL */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"delivery_company_url_new"} name="delivery_company_url[]" value={deliveryCompanyNew.delivery_company_url || ''} onChange={handleChangeCell('new', 'delivery_company_url')}></input>
                  </td>
                  {/* 作成者更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(deliveryCompanyNew)}
                    {renderDeleteButton(deliveryCompanyNew)}
                  </td>
                </tr>
                {deliveryCompanyData.map((data, idx) => (
                  <tr key={data.delivery_company_id}>
                    {/* 配送会社ID */}
                    <td className="text-center align-middle">
                      {data.delivery_company_id}
                    </td>
                    {/* 配送会社名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"delivery_company_name" + data.delivery_company_id} name="delivery_company_name[]" value={data.delivery_company_name || ''} onChange={handleChangeCell(idx, 'delivery_company_name')}></input>
                    </td>
                    {/* 配送状況確認URL */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"delivery_company_url" + data.delivery_company_id} name="delivery_company_url[]" value={data.delivery_company_url || ''} onChange={handleChangeCell(idx, 'delivery_company_url')}></input>
                    </td>
                    {/* 作成者 更新者*/}
                    <td className="text-center align-middle">
                      {data.ins_name}<br />
                      {data.upd_name}
                    </td>
                    {/* 作成日時 更新日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : ''}<br />
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeliveryCompany;