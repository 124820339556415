import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import util from 'util';
import constClass from '../../Constants/Constants'

const NavBar = (props) => {
  const { user, userActions, conditionActions } = props;
  const history = useHistory();

  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(`${props.match.path}`);
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    conditionActions.logout();
    history.replace(`${props.match.path}/login`);
  }

  // const handlePlanCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}plancategory`);
  // }

  // const handleProductCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}productcategory`);
  // }

  // const handleOrderInputClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}order/input`);
  // }

  // const handlePlanClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}plan`);
  // }

  // const handleProductClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}product`);
  // }

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${props.match.path}`}>
        モバイルオーダー<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
          {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}noticeinterval`}>通知頻度マスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}user`}>ユーザマスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}plancategory`}>企画カテゴリマスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}productcategory`}>商品カテゴリマスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}productdept`}>商品デプトマスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}deliverycompany`}>配送会社マスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}plan`}>企画</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}product`}>商品</Link>
              </li>
            }
            <li className="nav-item">
              <Link className="nav-link" to={`${props.match.path}`}>注文一覧</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={`${props.match.path}order/input`}>注文入力</Link>
            </li>
            {/* {user.userClass === constClass.CLASS.CHECK && */}
            {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  設定
              </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="#" onClick={handleDispCloseClick}>発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleLineCloseClick}>LINE発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleDispOpenClick}>発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleLineOpenClick}>LINE発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleWaitOpenClick}>順番待ち開始</a>
                  <a className="dropdown-item" href="#" onClick={handleWaitCloseClick}>順番待ち停止</a>
                </div>
              </li> */}
            {/* } */}
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          {/* <label className="mr-2 text-primary">{user.userName}</label> */}
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;